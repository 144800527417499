import { z } from "zod";

const isFullName = (val: string) => {
  const parts = val.trim().split(/\s+/);
  return parts.length >= 2;
};

export const createAccountFormSchema = z
  .object({
    email: z.string().email("Please enter a valid email address."),
    // name: z.string().refine(isFullName, "Please enter your full name"),
    username: z
      .string()
      .min(4, "Username must be at least 4 characters")
      .regex(/^[^\s]+$/, "Username cannot contain spaces"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .regex(/[0-9]/, "Password must contain at least one number.")
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character.",
      )
      .regex(/[a-z]/, "Password must contain at least one lowercase letter."),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match.",
    path: ["confirmPassword"], // This will show the error at the confirmPassword field
  });

export type CreateAccountFormSchema = z.infer<typeof createAccountFormSchema>;
