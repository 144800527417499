import { z } from "zod";

export const promoCodeSchema = z.object({
  promoCode: z
    .string()
    // .regex(/^PHEAE2025$/, "Invalid promo code.")
    .min(5, "Promo code must be at least 5 characters")
    .optional()
    .or(z.literal("")),
});

export type PromoCodeSchema = z.infer<typeof promoCodeSchema>;
