import Button from "@/components/buttons";
import TextComponent from "../TextComponent";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";
import Link from "next/link";

export default function ManageConsentModal() {
  const { actions } = useZStore();

  return (
    <div
      className={cn(
        // Container
        "relative rounded-lg xl:rounded-[20px] bg-white shadow-md",
        // Flex / layout
        "flex flex-col gap-4 xl:gap-6 items-center justify-center",
        // Adjust width/height to match the design
        "xl:w-[535px] xl:h-auto",

        "pt-10 px-10 pb-5",
      )}
    >
      {/* Close Button (X) */}
      <button
        className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        aria-label="Close"
        onClick={() => actions.closeModal()}
      >
        &times;
      </button>

      {/* Heading */}
      <TextComponent type="h3" className=" font-semibold text-[#05004E]">
        Manage Consent
      </TextComponent>

      {/* Description Text */}
      <TextComponent type="body" className="leading-relaxed text-[#96A5B8]">
        To provide the best experiences, we use technologies like cookies to
        store and/or access device information. Consenting to these technologies
        will allow us to process data such as browsing behavior or unique IDs on
        this site. Not consenting or withdrawing consent may adversely affect
        certain features and functions.
      </TextComponent>

      {/* Accept Button */}
      <Button
        variant="primary"
        className="w-full"
        onClick={() => actions.closeModal()}
      >
        Accept
      </Button>

      {/* Additional Links */}
      <div className="mt-2 flex flex-col items-center gap-2 text-sm text-[#979797] sm:flex-row sm:gap-4">
        <Link href="#">Opt-out preferences</Link>
        <div className="h-3 w-px bg-black"></div>
        <Link href="https://emailauditengine.com/blog/privacy-policy">
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}
