"use client";
import React from "react";
import Image from "next/image";
import cn from "@/utils/cn";
import Button from "@/components/buttons";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";

const VerifyEmailModal: React.FC = () => {
  const { actions } = useZStore();

  return (
    <div
      className={cn(
        "relative flex w-[282px] h-[290px] xl:w-[627px] xl:h-[319px] md:w-[470.25px] md:h-[239.25px] overflow-hidden rounded-[20px] bg-white ",
      )}
    >
      <div className="bg-white pl-[42px] pt-6 md:pl-[32px]  md:pt-[30px] xl:pl-[100px] xl:pt-[44px]">
        <div className="h-[60px] w-[198px] text-center text-[23px] font-semibold text-[#05004E] md:h-min md:w-full  xl:text-[35px]">
          Please Verify Your Email
        </div>
        <div className="mt-[8px] h-[30px] text-center text-[10.5px] text-[#96A5B8]  xl:mt-[10px] xl:text-sm">
          <div className="flex  justify-center">
            <div className=" w-[200px] ">
              We have sent an email to
              <span className="text-[#336DFF]"> example@example.com</span>
            </div>
          </div>
        </div>
        <div className=" h-min w-[200px] pt-[24px] text-center text-[10.5px] text-[#96A5B8] md:w-[400px]   xl:text-sm">
          Click on the link in that email to complete your signup. Still can't
          find the email? Resend link or check your spam folder
        </div>

        <div className="mt-[25px] flex items-center justify-center space-x-4">
          <Button
            type="button"
            variant="primary"
            className="font-semibold text-white md:h-[30px] md:w-[109px] md:rounded-[9px] md:text-xs xl:h-[40px] xl:w-[141px] xl:rounded-xl xl:text-[16px]"
            onClick={() => {
              // Simulate resend email functionality
              console.log("Resend email clicked");
            }}
          >
            Resend Email
          </Button>
        </div>
      </div>

      <div className="" onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

export default VerifyEmailModal;
