"use client";
import React from "react";
import cn from "@/utils/cn";
import Image from "next/image";
import { useFormContext } from "react-hook-form";
import InputCrossIcon from "@/assets/icons/input-cross-icon.svg";
import { InputErrorIcon } from "@/assets/svg-icons/svg-icons";

type InputFieldProps = {
  name: string;
  isRequired?: boolean;
  subLabel?: string;
  errorTextPosition?: "right" | "top";
  showErrorInTooltip?: boolean;
  labelStyle?: React.CSSProperties;
  containerClassName?: string;
  successMessage?: string | null;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function SignupInputField({
  name,
  className,
  isRequired = false,
  errorTextPosition = "right",
  containerClassName = "",
  successMessage,
  ...props
}: InputFieldProps) {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const value = watch(name);
  const error = errors?.[name]?.message;

  return (
    <div className={cn("w-full", containerClassName)}>
      <div
        className={cn("flex items-center ", {
          "flex-col-reverse items-start": errorTextPosition === "top",
        })}
      >
        {error && !successMessage && (
          <div className="ml-2 flex items-start">
            <div className="flex items-center">
              <InputErrorIcon />
              <p className="ml-1 text-[7px] text-[#FA5A7D] xl:text-xs">
                {name === "password"
                  ? "Please enter a valid password"
                  : String(error)}
              </p>
            </div>
          </div>
        )}
        {successMessage && ( // Show success message if it exists
          <div className="ml-2 flex items-start">
            <div className="flex items-center">
              <svg // You might want to create a success icon component
                className="h-4 w-4 text-green-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
              <p className="ml-1 text-[7px] text-green-500 xl:text-xs">
                {successMessage}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="relative mt-1">
        <input
          id={name}
          className={cn(
            "w-full bg-[#F9FAFB] rounded-[100px] pl-3 pr-9 md:pb-1.5 md:pt-1.5 py-1 placeholder:text-[#8E8E93] xl:placeholder:text-sm font-bold relative xl:text-sm text-[10.5px] placeholder:text-[10.5px] focus:outline-none focus:ring-1 focus:ring-kd-lte-blue border border-[#EDF2F6]",
            error && "focus:ring-[#FA5A7D]",
            "h-10 w-full rounded-[10.56px] bg-[#F9FAFB] px-[24px] text-[9px] font-normal xl:h-[60px] xl:rounded-2xl xl:text-sm",
            className
          )}
          {...props}
          {...register(name)}
          value={value || ""}
        />

        {watch(name) && (
          <div
            onClick={() => {
              setValue(name, "");
            }}
            className="absolute inset-y-0 right-[16px] flex h-full cursor-pointer items-center justify-center"
          >
            <Image src={InputCrossIcon} alt="Clear input field" />
          </div>
        )}
      </div>
    </div>
  );
}
