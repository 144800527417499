"use client";
import { useEffect, Suspense } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useZStore } from "@/utils/z-store";
import cn from "@/utils/cn";
import {
  CreateAccountFormModal,
  DashboardIntro,
  DashboardWelcomeModal,
  JoinWaitlistModal,
  MailchimpAPISettingsModal,
  SignInModal,
  ReportBuilderModal,
  ForgotPasswordModal,
  ResetPasswordModal,
  VerifyEmailModal,
  InviteAccessModal,
} from "./modals";
import InvitationModal from "./modals/InvitationModal";
import ManageConsentModal from "./modals/ManageConsentModal";
import BillingModalContent from "../(payment)/payment/page";

interface ModalProps {
  className?: string;
  inviteToken?: string;
}

const modalComponents: any = {
  "start-free-trial": JoinWaitlistModal,
  "sign-up": CreateAccountFormModal,
  "sign-in": SignInModal,
  "dashboard-welcome": DashboardWelcomeModal,
  "mailchimp-api-key": MailchimpAPISettingsModal,
  "report-builder": ReportBuilderModal,
  "dashboard-intro": DashboardIntro,
  "invite-user": InvitationModal,
  "manage-consent": ManageConsentModal,
  "forgot-password": ForgotPasswordModal,
  "reset-password": ResetPasswordModal,
  "verify-email": VerifyEmailModal,
  "invite-access": InviteAccessModal,
  "billing-modal": BillingModalContent,
};

const ModalContent: React.FC<ModalProps> = ({ className }) => {
  const { data, actions } = useZStore();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const isHomePage = pathname === "/";
  const isDashboardPageModal = ![
    "start-free-trial",
    "sign-up",
    "sign-in",
    "invite-user",
    "manage-consent",
    "forgot-password",
    "reset-password",
    "verify-email",
    "invite-access",
  ].includes(data.modal.modalName || "");

  const modalName = searchParams.get("modal");
  const inviteToken = searchParams.get("inviteToken");

  useEffect(() => {
    if (modalName) {
      actions.openModal({ modalName });
    }
  }, [searchParams, actions]);

  useEffect(() => {
    // Update the URL query parameters when the modal changes
    const params = new URLSearchParams(searchParams.toString());

    if (
      data.modal.isOpen &&
      ["sign-in", "sign-up"].includes(data.modal.modalName || "")
    ) {
      // Add or update the `modal` parameter in the URL
      params.set("modal", data.modal.modalName || "");
    } else if (!data.modal.isOpen) {
      // Remove the `modal` parameter if no modal is open
      params.delete("modal");
    }

    // Construct the new URL
    const queryString = params.toString();
    const newPath = `${pathname}${queryString ? `?${queryString}` : ""}`;

    // Replace the current URL without reloading the page
    router.replace(newPath);
  }, [data.modal.isOpen, data.modal.modalName, pathname, router, searchParams]);

  if (!data.modal.isOpen) return null;

  const ModalComponent = data.modal.modalName
    ? modalComponents[data.modal.modalName || ""]
    : null;

  return (
    <dialog
      className={cn(
        "fixed left-0 top-0 z-40 flex h-full w-full items-center justify-center overflow-auto bg-black/[40%]",
        className,
        {
          hidden: isHomePage && isDashboardPageModal,
        },
      )}
    >
      {data.modal.content && data.modal.modalName == null && (
        <div className="">{data.modal.content}</div>
      )}
      {ModalComponent && (!isHomePage || !isDashboardPageModal) && (
        <ModalComponent inviteToken={inviteToken} />
      )}
    </dialog>
  );
};

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ModalContent {...props} />
    </Suspense>
  );
};

export default Modal;
