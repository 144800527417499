"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";
import React, { useState, useEffect } from "react";
import SignupInputField from "../SignupInputField";
import { useSearchParams, useRouter } from "next/navigation";

const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(6, "Password must be at least 6 characters long.")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .regex(/[0-9]/, "Password must contain at least one number."),
});

type ResetPasswordFormData = z.infer<typeof resetPasswordSchema>;

const ResetPasswordModal: React.FC = () => {
  const { actions } = useZStore();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();

  const token = searchParams.get("token");

  const methods = useForm<ResetPasswordFormData>({
    resolver: zodResolver(resetPasswordSchema),
  });

  // Verify token when component mounts
  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setFormError("Invalid or missing reset token");
        return;
      }

      try {
        const response = await fetch("/api/verify-reset-token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error("Invalid or expired reset token");
        }

        setIsValidToken(true);
      } catch (error) {
        setFormError(
          "This reset link is invalid or has expired. Please request a new one."
        );
        setIsValidToken(false);
      }
    };

    verifyToken();
  }, [token]);

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: ResetPasswordFormData) => {
    if (!token || !isValidToken) {
      setFormError("Invalid reset token");
      return;
    }

    setIsLoading(true);
    setFormError(null);

    try {
      const response = await fetch("/api/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          newPassword: data.password,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        // throw new Error(result.message || "Failed to reset password");
      }

      setIsSuccess(true);

      // Redirect to login page after 2 seconds
      setTimeout(() => {
        actions.closeModal();
        router.push("/dashboard/home"); // Adjust the path according to your routing
      }, 2000);
    } catch (error: any) {
      // setFormError(
      //   error.message || "An unexpected error occurred. Please try again."
      // );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={cn(
        "relative flex w-[262px] h-[340px] xl:w-[627px] xl:h-[350px] md:w-[470.25px] md:h-[279.25px] overflow-hidden rounded-[20px] bg-white "
      )}
    >
      <div className=" bg-white pl-8 pt-6 md:pl-[41.25px] md:pt-[30px] xl:pl-[55px] xl:pt-[44px]">
        <div className=" h-[60px] w-[198px] text-center text-[23px] font-semibold text-[#05004E] md:h-min md:w-full md:text-start xl:text-[35px]">
          Reset Your Password
        </div>
        <div className="mt-[8px] h-[30px] w-[204px] text-center text-[10.5px] text-[#96A5B8] md:h-min md:w-full md:text-start xl:mt-[10px] xl:text-sm">
          Almost done. Enter your new password, and you're good to go.
        </div>

        {isSuccess && (
          <div className="mt-6 w-[196px] text-center text-xs text-[#00E096] md:w-full md:text-left md:text-sm">
            Your password has been reset successfully! Redirecting to login...
          </div>
        )}

        {formError && (
          <div className="my-6 rounded-lg bg-red-50 p-3 text-sm text-red-500">
            {formError}
          </div>
        )}

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={cn(
              "mt-[19px] w-full xl:mt-[25px]",
              formError !== null && "hidden"
            )}
          >
            <div className="">
              <SignupInputField
                id="forgot-password-email"
                name="password"
                type="password"
                placeholder="Password"
                className=" flex h-10 w-[204px] rounded-[12px] pl-4 font-normal tracking-[4px] placeholder:tracking-normal md:h-[45px] md:w-[387px] md:pl-5 xl:h-[60px] xl:w-[517px] xl:rounded-[16px] xl:pl-6 "
                errorTextPosition="top"
              />
            </div>

            <div className=" mt-[25px] flex-col-reverse items-center space-x-4 md:flex md:flex-row md:justify-between ">
              <Button
                type="submit"
                variant="primary"
                disabled={isLoading}
                className="font-semibold text-white md:h-[30px] md:w-[135px] md:rounded-[9px] md:text-xs xl:h-[40px] xl:w-[175px] xl:rounded-xl xl:text-[16px]"
              >
                {isLoading ? "Updating..." : "Update Password"}
              </Button>
              <div>
                {methods.formState.errors.password && (
                  <div className="-ml-4 mt-2 md:ml-0 md:mt-0  md:pl-[41px] xl:-mb-[39px] xl:pl-[55px] ">
                    <ul className="w-[155px] rounded-lg bg-[#FFF1F2] p-2 text-[8px] text-[#FA5A7D]  md:-mt-1 md:text-[9px] xl:-mt-2 xl:text-[10px]">
                      <li>• Be at least 6 characters</li>
                      <li>
                        • Must have at least one uppercase letter
                        (&apos;A&apos;-&apos;Z&apos;)
                      </li>

                      <li>
                        • Must have at least one number
                        (&apos;0&apos;-&apos;9&apos;)
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="" onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

export default ResetPasswordModal;
