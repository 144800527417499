"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";
import React, { useState } from "react";
import SignupInputField from "../SignupInputField";

// Email validation schema
const forgotPasswordSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
});

type ForgotPasswordFormData = z.infer<typeof forgotPasswordSchema>;

const ForgotPasswordModal: React.FC = () => {
  const { actions } = useZStore();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(0);

  const methods = useForm<ForgotPasswordFormData>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const onSubmit = async (data: ForgotPasswordFormData) => {
    setIsLoading(true);
    setFormError(null);
    setSuccessMessage(null);

    try {
      const response = await fetch("/api/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: data.email }),
      });

      const result = await response.json();

      if (!response.ok) {
        // throw new Error(result.message || "Failed to send reset email");
        setError("email", {
          type: "manual",
          message: "Invalid Email",
        });
      }

      setEmailSent(true);
      setSuccessMessage(
        "An email has been sent to your address with reset instructions."
      );
      setCountdown(60); // Start 60 seconds countdown
    } catch (error: any) {
      // setFormError(
      //   error.message || "An unexpected error occurred. Please try again."
      // );
    } finally {
      setIsLoading(false);
    }
  };

  // Use useEffect to handle the timeout
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (successMessage) {
      timeoutId = setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    }

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [successMessage]);

  // Handle countdown timer
  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [countdown]);

  return (
    <div
      className={cn(
        "relative flex w-[262px] h-[270px] xl:w-[627px] xl:h-[319px] md:w-[470.25px] md:h-[239.25px] overflow-hidden rounded-[20px] bg-white "
      )}
    >
      <div className=" bg-white pl-8 pt-6 md:pl-[41.25px] md:pt-[30px] xl:pl-[55px] xl:pt-[44px]">
        <div className=" h-[60px] w-[198px] text-center text-[23px] font-semibold text-[#05004E] md:h-min md:w-full md:text-start xl:text-[35px]">
          Forgot Your Password?
        </div>
        <div className="mt-[8px] h-[30px] w-[204px] text-center text-[10.5px] text-[#96A5B8] md:h-min md:w-full md:text-start xl:mt-[10px] xl:text-sm">
          No worries! We'll send you an email link to reset your password.
        </div>

        {
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mt-[19px] w-full xl:mt-[25px]"
            >
              <div className="">
                <SignupInputField
                  id="forgot-password-email"
                  name="email"
                  type="email"
                  placeholder="Username or email address"
                  className=" flex h-10 w-[204px] rounded-[12px] pl-4 font-normal md:h-[45px] md:w-[387px]  md:pl-5 xl:h-[60px] xl:w-[517px] xl:rounded-[16px] xl:pl-6 "
                  errorTextPosition="top"
                  successMessage={successMessage}
                />
              </div>

              <div className="mt-[25px] flex items-center space-x-4 ">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isLoading || countdown > 0}
                  className={cn(
                    "font-semibold text-white md:h-[30px] md:w-[109px] md:rounded-[9px] md:text-xs xl:h-[40px] xl:w-[141px] xl:rounded-xl xl:text-[16px]",
                    (isLoading || countdown > 0) &&
                      "opacity-50 cursor-not-allowed"
                  )}
                >
                  {isLoading
                    ? "Sending..."
                    : countdown > 0
                      ? `Resend in ${countdown}s`
                      : "Resend Email"}
                </Button>
                <div className=" text-center">
                  <button
                    onClick={() => actions.openModal({ modalName: "sign-in" })}
                    className="text-[10.5px] text-[#336DFF] underline xl:text-sm"
                  >
                    Return to login
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        }
      </div>

      <div className="" onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
