"use client";

import Button from "@/components/buttons";
import { useZStore } from "@/utils/z-store";
import { FaMailchimp } from "react-icons/fa";
import TextComponent from "../TextComponent";
import cn from "@/utils/cn";
import {
  FadedBgCircleBottom,
  FadedBgCircleTop,
} from "@/assets/svg-icons/svg-icons";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  promoCodeSchema,
  PromoCodeSchema,
} from "@/utils/schemas/promo-code-schema";
import SignupInputField from "../SignupInputField";
import Image from "next/image";
import { FiClock } from "react-icons/fi";
import ConnectedCheckIcon from "@/assets/icons/connected-icon.svg";

const DashboardWelcome = () => {
  const { actions } = useZStore();
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const promocode = "PHEAE2025";

  const formMethods = useForm<PromoCodeSchema>({
    resolver: zodResolver(promoCodeSchema),
    defaultValues: {
      promoCode: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handlePromoSubmit = (data: PromoCodeSchema) => {
    setIsLoading(true); // Show loading state if needed

    if (data.promoCode === promocode) {
      setConnected(true);

      // Set a timeout to wait for 5 seconds
      setTimeout(() => {
        setConnected(false);
        // Open the modal after showing the success message
        actions.openModal({ modalName: "mailchimp-api-key" });
      }, 3000);
    } else {
      // Handle invalid promo code case if needed
      formMethods.setError("promoCode", {
        type: "manual",
        message: "Invalid promo code",
      });
    }

    setIsLoading(false);
  };

  return (
    <div
      className={cn(
        "min-h-[331px] md:min-h-min w-[262px] overflow-hidden rounded-[20px] bg-white md:h-[263px] md:w-[549px] xl:h-[351px] xl:w-[1116px] -ml-10 sm:ml-0",
      )}
    >
      <div className="relative flex h-full flex-col overflow-hidden sm:flex-col md:flex-row">
        <div className="relative flex grow flex-col items-center justify-center bg-kd-lte-blue p-5 text-white md:p-11 xl:p-10">
          <div className="max-w-full text-center sm:max-w-[200px] md:max-w-[245px] md:text-start xl:max-w-[581.95px]">
            <TextComponent type="h2" className="text-left">
              Welcome to the Engine!
            </TextComponent>
            <TextComponent type="body" className="pt-2.5 text-left text-white">
              Monitor performance, track key metrics, and identify data trends
              to gain valuable insights into your campaigns by leveraging your
              existing data with integrations.
            </TextComponent>
            <div className="mt-3 flex items-center space-x-6 sm:mt-4 md:mt-5 xl:mt-11">
              {showPromoInput ? (
                <FormProvider {...formMethods}>
                  <form
                    onSubmit={handleSubmit(handlePromoSubmit)}
                    className="w-full flex-col space-y-4 md:flex md:flex-row md:items-center md:justify-center  md:space-x-4 md:space-y-0"
                  >
                    <div className="relative w-full">
                      <TextComponent
                        onClick={() =>
                          // actions.openModal({ modalName: "mailchimp-api-key" })
                          setShowPromoInput(false)
                        }
                        type="caption"
                        className="absolute -top-3 right-0 cursor-pointer text-right text-white underline xl:-top-4"
                      >
                        I do not have a code
                      </TextComponent>
                      <SignupInputField
                        name="promoCode"
                        placeholder="Add promo code"
                        className={`w-full border bg-[#5B89FE] uppercase tracking-[6px] text-white placeholder:normal-case placeholder:tracking-normal placeholder:text-[#EDF2F6] xl:tracking-[8px] ${
                          errors.promoCode
                            ? "border-[#FA5A7D]"
                            : "border-[#0e161c]"
                        }`}
                      />
                    </div>
                    {/* {errors.promoCode && (
                      <p className="text-xs text-[#FA5A7D]">
                        {errors.promoCode.message}
                      </p>
                    )} */}

                    <Button
                      type="submit"
                      variant="secondary"
                      disabled={isLoading}
                      className="z-20 h-[23px] w-full bg-white text-[9px] text-[#336DFF] md:mx-0 md:h-[30px] md:w-[79px] md:text-[9px] xl:h-10 xl:w-[105px] xl:text-base"
                    >
                      <TextComponent className="text-nowrap" type="button">
                        {isLoading ? "Processing..." : "Continue"}
                      </TextComponent>
                    </Button>
                    {/* <Button
                      variant="tertiary"
                      onClick={() => setShowPromoInput(false)}
                    >
                      <TextComponent className="text-nowrap" type="button">
                        Cancel
                      </TextComponent>
                    </Button> */}
                  </form>
                </FormProvider>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    className="z-20 mx-auto h-[23px] w-[204px] bg-white text-[9px] text-[#336DFF] md:mx-0 md:text-[9px] xl:h-10 xl:text-base"
                    onClick={() =>
                      actions.openModal({ modalName: "mailchimp-api-key" })
                    }
                  >
                    <TextComponent className="text-nowrap" type="button">
                      Continue
                    </TextComponent>
                  </Button>
                  <button onClick={() => setShowPromoInput(true)}>
                    <TextComponent className="text-[#EDF2F6]" type="body">
                      Add promo code
                    </TextComponent>
                  </button>
                </>
              )}
            </div>
          </div>
          <FadedBgCircleTop className="absolute -right-40 -top-10 z-0 scale-50 xl:-right-40 xl:top-0 xl:scale-100" />
          <FadedBgCircleBottom className="absolute -bottom-10 -left-10 z-0 scale-50 xl:bottom-0 xl:left-0 xl:scale-100" />
        </div>
        <div className="my-[30px] bg-white md:my-0 md:w-[183px] xl:w-[372px]">
          <div className="flex h-full flex-col items-center justify-center px-[19px] text-center md:px-[14px] xl:px-[28px] 2xl:px-10">
            <div className="flex size-10 items-center justify-center rounded-full bg-[#FFDD22] xl:size-20">
              <FaMailchimp className="size-full p-1" />
            </div>

            <TextComponent
              type="h3"
              className="mt-2.5 font-semibold text-[#05004E] xl:mt-3 2xl:mt-[9.5px]"
            >
              Connect Mailchimp
            </TextComponent>

            <TextComponent type="body" className="mt-[5px] text-[#96A5B8]">
              Integrate Mailchimp to coordinate cross-channel email campaigns.
            </TextComponent>

            <TextComponent
              type="caption"
              className={`mt-[10px] inline-flex items-center ${"text-[#00E096]"}`}
            >
              {connected && (
                <>
                  <Image alt="" src={ConnectedCheckIcon} className="mr-[7px]" />
                  Successfully trial extended
                </>
              )}
            </TextComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWelcome;
