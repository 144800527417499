"use client";
import React from "react";
import Image from "next/image";
import cn from "@/utils/cn";
import Button from "@/components/buttons";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";

const InviteAccessModal: React.FC = () => {
  const { actions } = useZStore();

  return (
    <div
      className={cn(
        "relative flex w-[262px] h-[208.5px] xl:w-[627px] xl:h-[278px] md:w-[470.25px] md:h-[193.5px] overflow-hidden rounded-[20px] bg-white flex-col justify-center items-center",
      )}
    >
      <div className="text-center">
        <div className=" ml-3 w-[204px] text-center text-[23px] font-semibold text-[#05004E] md:ml-0 md:h-min md:w-full md:pl-0 xl:text-[35px]">
          You Have Been Invited!
        </div>
        <div className="ml-6 mt-[8px] h-[30px] w-[173px] text-center text-[10.5px] text-[#96A5B8] md:ml-0 md:h-min md:w-full xl:mt-[10px] xl:text-sm">
          Continue to get started and access shared data.
        </div>

        <div className="mt-[25px] flex items-center justify-center space-x-4">
          <Button
            type="button"
            variant="primary"
            className="font-semibold text-white md:h-[30px] md:w-[175px] md:rounded-[9px] md:text-xs xl:h-[40px] xl:w-[225px] xl:rounded-xl xl:text-[16px]"
            onClick={() => {
              // Simulate resend email functionality
              console.log("Resend email clicked");
            }}
          >
            Activate Shared Access
          </Button>
        </div>
      </div>

      <div onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-3 top-3 cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

export default InviteAccessModal;
