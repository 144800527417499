"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";
import { signIn, signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import React from "react";
import InputField from "../SignupInputField";

const signInSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
  password: z
    .string()
    .min(8, "Your password must be at least 8 characters long.")
    .regex(/[A-Z]/, "Your password must contain at least one capital letter.")
    .regex(/[0-9]/, "Your password must contain at least one number."),
});

type SignInFormData = z.infer<typeof signInSchema>;

interface SignInModalProps {
  inviteToken?: string;
  className?: string;
}

const SignInModal: React.FC<SignInModalProps> = ({ inviteToken }) => {
  const { actions } = useZStore();
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState<string | null>(null);

  const methods = useForm<SignInFormData>({
    resolver: zodResolver(signInSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const onSubmit = async (data: SignInFormData) => {
    setIsLoading(true);
    setFormError(null);

    try {
      await signOut({ redirect: false });

      const response = await signIn("credentials", {
        email: data.email,
        password: data.password,
        redirect: false,
      });

      if (!response?.error) {
        actions.resetStore();
        router.push("/dashboard/home");
        router.refresh();
        actions.closeModal();
      } else {
        setError("email", {
          type: "manual",
          message: "Invalid email or password",
        });
        setError("password", {
          type: "manual",
          message: "Invalid email or password",
        });
      }
    } catch (error) {
      setFormError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setFormError(null);

    try {
      await signOut({ redirect: false });

      if (inviteToken) {
        localStorage.setItem("inviteToken", inviteToken);
      }
      actions.resetStore();
      await signIn("google", {
        callbackUrl: "/dashboard/home",
        redirect: true,
      });
      actions.closeModal();
    } catch (error) {
      setFormError("Unable to sign in with Google. Please try again.");
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    actions.openModal({ modalName: "forgot-password" });
  };

  return (
    <div
      className={cn(
        "relative flex w-[262px] h-[693px] items-center justify-center overflow-hidden rounded-[20px]  bg-white md:h-[418px] md:w-[550px] xl:h-[596px] xl:w-[1067px]",
      )}
    >
      <div
        className={cn(
          "h-full w-full bg-white md:pb-[28px] pb-[10px] text-[#444A6D] md:w-[65%] pt-[42px] md:pt-[42px] xl:pb-[56px] xl:pt-[55px] ",
        )}
      >
        <div className=" flex justify-center px-[29px] text-center text-[23px] font-semibold text-[#05004E] md:px-0 xl:text-[35px]">
          Sign in to Your Engine
        </div>
        {/* signup with google and apple */}
        <div className="flex justify-center pb-3 pt-[10px] md:pb-[19.25px] md:pt-[16px] xl:pb-[26px] xl:pt-[36px] ">
          <Button
            id="google-sign-in"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="flex h-[44.77px] w-[47.75px] items-center justify-center rounded-xl border border-[#EDF2F6] bg-[#FAFBFC]  text-kd-gray-text xl:h-[60px] xl:w-[64px] xl:rounded-[16px]"
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <>
                <FcGoogle className="size-[18px] xl:size-6" />
              </>
            )}
          </Button>
        </div>
        <div className=" flex items-center justify-center space-x-2 xl:space-x-5  ">
          <div className="h-px w-1/5 bg-[#EDF2F6]"></div>
          <div className=" text-[10.5px] text-[#96A5B8] xl:text-sm">
            or sign in with email
          </div>
          <div className="h-px w-1/5 bg-[#EDF2F6]"></div>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" flex justify-center pt-2 md:pt-[18px] xl:pt-[25px]">
              <div>
                <InputField
                  id="email-sign-in"
                  name="email"
                  type="text"
                  placeholder="Username or email address"
                  className="mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal md:mb-[8px] md:h-[40px] md:w-[280px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[592px] xl:rounded-2xl"
                  errorTextPosition="top"
                />
                <div className="">
                  <InputField
                    name="password"
                    type="password"
                    className="mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal tracking-[4px] placeholder:tracking-normal md:mb-[8px] md:h-[40px] md:w-[280px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[592px] xl:rounded-2xl"
                    placeholder="Password"
                    isRequired={true}
                    errorTextPosition="top"
                  />
                  <div className=" flex justify-center">
                    <div
                      onClick={handleForgotPassword}
                      className=" mt-2 w-[161px] cursor-pointer border-b border-[#EDF2F6]  pb-1 text-center text-[9px] text-kd-gray-text md:text-[10.8px] xl:text-sm  "
                    >
                      Forgot your password?
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-9 flex items-center justify-center space-x-[10px] md:space-x-[15px] ">
              <Button
                id="sign-in"
                type="submit"
                variant="primary"
                disabled={isLoading}
                className={cn(
                  "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 text-[12px] font-semibold leading-5 xl:h-10 xl:w-[107px] xl:text-base ",
                )}
              >
                {isLoading ? (
                  <AiOutlineLoading3Quarters className="animate-spin" />
                ) : (
                  "Sign in"
                )}
              </Button>
            </div>
          </form>

          <div
            className={cn(
              "relative mt-12 size-full overflow-hidden bg-kd-lte-blue text-white md:hidden",
            )}
          >
            <div className="absolute -right-36 -top-36 -z-0 h-[227.25px] w-[229.5px]  rotate-90 rounded-full bg-gradient-to-tl  from-white/40 via-transparent to-white/0 xl:-right-36 xl:-top-52"></div>

            <div className=" flex h-full justify-center">
              <div className=" px-[29px] pt-[42px]">
                <div className=" flex justify-center px-3 text-center text-lg font-semibold ">
                  Start Your Engine
                </div>
                <div className=" flex  justify-center py-2">
                  <div className=" w-[170px] text-center text-xs text-[#D1D1D6]">
                    Don’t have an account? Enter your details and start your
                    journey with us
                  </div>
                </div>
                <div className=" flex justify-center">
                  <Button
                    variant="inverse"
                    onClick={() => actions.openModal({ modalName: "sign-up" })}
                    className={cn(
                      "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 md: text-[12px] font-semibold xl:h-10 xl:w-[107px] xl:text-base ",
                    )}
                    disabled={isLoading}
                  >
                    Sign up
                  </Button>
                </div>
                <div className="flex justify-center pt-4">
                  <div className="text-center text-[8px] text-[#D1D1D6]">
                    By signing up, you agree to Email Audit Engine`&lsquo;s
                    Terms of Services and Privacy Policy.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </div>
      <div className="relative hidden h-full w-[35%] overflow-hidden bg-kd-lte-blue text-white md:block">
        <div className="absolute -right-36 -top-36 -z-0 h-[227.25px] w-[229.5px] rotate-90 rounded-full bg-gradient-to-tl  from-white/40 via-transparent to-white/0 md:-right-28 md:-top-40 xl:-right-36 xl:-top-52 xl:h-[303px] xl:w-[306px]"></div>

        <div
          className={cn(
            "absolute -bottom-52 -left-44 -z-0 rounded-full bg-gradient-to-bl from-white/30 via-transparent to-white/0 md:-bottom-[120px] md:-left-[70px] md:size-[181.5px] xl:-bottom-[168px] xl:-left-20 xl:size-[242px]",
          )}
        ></div>
        <div className=" flex h-full justify-center">
          <div className="md:pt-[135px] xl:pt-[228px]">
            <div className=" flex px-3 text-center text-xs font-semibold md:text-[23px] md:leading-[30px] xl:text-[35px] xl:leading-[40px]">
              Start Your Engine
            </div>
            <div className=" flex  justify-center md:pb-[21px] md:pt-[12px] xl:pb-[26px] xl:pt-[13px]">
              <div className=" text-center text-[#D1D1D6] md:h-[48px] md:w-[158px] md:text-[10.5px] xl:h-[50px] xl:w-[257px] xl:text-sm">
                Don’t have an account? Enter your details and start your journey
                with us
              </div>
            </div>
            <div className=" flex justify-center">
              <Button
                variant="inverse"
                onClick={() => actions.openModal({ modalName: "sign-up" })}
                className={cn(
                  "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 md: text-[12px] font-semibold xl:h-10 xl:w-[107px] xl:text-base ",
                )}
              >
                Sign up
              </Button>
            </div>
            <div className="flex justify-center md:pt-[67.5px] xl:pt-[133px]">
              <div className="text-center text-[#D1D1D6] md:w-[140px] md:text-[6px] xl:w-[206px] xl:text-[8px]">
                By signing up, you agree to Email Audit Engine`&lsquo;s Terms of
                Services and Privacy Policy.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="" onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
        />
      </div>
    </div>
  );
};

export default SignInModal;
