"use client";
import { FloatingChatIcon } from "@/assets/svg-icons/svg-icons";
import { FC, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion, AnimatePresence } from "framer-motion";
import SignupInputField from "../SignupInputField";
import Button from "@/components/buttons";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import Link from "next/link";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const supportFormSchema = z.object({
  fullName: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email address"),
  message: z.string().min(10, "Message must be at least 10 characters"),
});

type SupportFormData = z.infer<typeof supportFormSchema>;

const SupportForm: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Need Support?");

  const methods = useForm<SupportFormData>({
    resolver: zodResolver(supportFormSchema),
    defaultValues: {
      fullName: "",
      email: "",
      message: "",
    },
  });

  const onSubmit = async (data: SupportFormData) => {
    try {
      setIsSubmitting(true);
      const response = await fetch("/api/support", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      const result = await response.json();

      // Update success message from API response
      setSuccessMessage(result.message || "Thank you for your message!");

      // Reset form
      methods.reset();

      // Close popup after 3 seconds
      setTimeout(() => {
        setIsOpen(false);
        // Reset the header text after closing
        setTimeout(() => {
          setSuccessMessage("Need Support?");
        }, 300);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>
        <FloatingChatIcon className="fixed bottom-10 right-[30px] z-30 size-[54px] cursor-pointer hover:scale-[95%] 2xl:right-[53px] 2xl:size-[73px]" />
      </div>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
              className="fixed inset-0 z-40 bg-black/50"
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.9, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 20 }}
              className=" fixed bottom-24 right-[30px] z-50 -translate-x-1/2 -translate-y-1/2 2xl:bottom-40 2xl:right-[53px]"
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <section className="relative min-h-[404.25px] w-[262px] 2xl:min-h-[555px] 2xl:w-[535px]">
                    <div className="size-full rounded-[15px] border border-[#edf2f6] bg-white pb-10 shadow-[0px_1.5px_4.5px_1.5px_rgba(0,0,0,0.15)] 2xl:rounded-[20px] 2xl:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15)]">
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        aria-label="Close support form"
                        className="absolute right-[13.50px] top-[13.50px] h-[14.75px] w-[14.62px] 2xl:right-[18px] 2xl:top-[18px] 2xl:h-[19.67px] 2xl:w-[19.50px]"
                      >
                        <Image
                          src={CloseIcon}
                          alt="close icon"
                          className="absolute right-3 top-3 cursor-pointer hover:opacity-60"
                        />
                      </button>

                      <header className="px-[29.62px] pt-[34.25px] 2xl:px-[55px] 2xl:pt-[59px]">
                        <h2 className="text-[23px] font-semibold leading-[30px] text-[#336dff] 2xl:text-[35px] 2xl:leading-10">
                          {successMessage}
                        </h2>
                        <p className="mt-[6px] text-[10.50px] font-normal leading-[15px] text-[#96a5b8] 2xl:mt-[10px] 2xl:text-sm 2xl:leading-tight">
                          Any question or remarks? Just write us a message!
                        </p>
                      </header>

                      {/* Rest of your form fields */}
                      <div className="mt-[45px] px-[29.26px] 2xl:mt-[45px] 2xl:px-[55px]">
                        <div className="space-y-[12px] 2xl:space-y-5">
                          <SignupInputField
                            name="fullName"
                            placeholder="Full Name"
                            type="text"
                          />
                          <SignupInputField
                            name="email"
                            placeholder="Email address"
                            type="email"
                          />
                          <textarea
                            {...methods.register("message")}
                            placeholder="Your message"
                            className={`h-[105px] w-full rounded-xl border bg-gray-50 px-[18px] py-[15px] 2xl:h-[140px] 2xl:rounded-2xl 2xl:px-6 2xl:py-5 ${
                              methods.formState.errors.message
                                ? "border-[#FA5A7D]"
                                : "border-[#edf2f6]"
                            } text-[10.50px] font-normal text-kd-grey 2xl:text-sm`}
                          />
                          {methods.formState.errors.message && (
                            <p className="text-xs text-[#FA5A7D]">
                              {methods.formState.errors.message.message}
                            </p>
                          )}
                        </div>

                        <div className="mt-5 flex items-center gap-2 2xl:mt-[21px]">
                          <Button
                            id="support"
                            type="submit"
                            variant="primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Send Message"}
                          </Button>

                          <div className="ml-auto hidden gap-2 2xl:flex">
                            <div className="mb-4 mt-[14px] flex space-x-[15px] 2xl:mb-[22px] 2xl:mt-[20px]">
                              {[
                                {
                                  platform: "TikTok",
                                  href: "https://www.tiktok.com/@emailauditengine",
                                  icon: (
                                    <FaTiktok className="size-full text-kd-lte-blue" />
                                  ),
                                },
                                {
                                  platform: "Instagram",
                                  href: "https://www.instagram.com/emailaudit.engine/",
                                  icon: (
                                    <FaInstagram className="size-full text-kd-lte-blue" />
                                  ),
                                },
                                {
                                  platform: "YouTube",
                                  href: "https://www.youtube.com/@EmailAuditEngine",
                                  icon: (
                                    <FaYoutube className="size-full text-kd-lte-blue" />
                                  ),
                                },
                              ].map((social) => (
                                <Link
                                  key={social.platform}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={social.href}
                                  className="flex size-[30px] cursor-pointer items-center justify-center rounded-xl border border-[#EDF2F6] bg-white p-2 hover:border-kd-lte-blue 2xl:size-10 2xl:p-2.5"
                                >
                                  {social.icon}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </FormProvider>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default SupportForm;
