"use client";
import Button from "@/components/buttons";
import CloseIcon from "./assets/close-icon.svg";
import Image from "next/image";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateAccountFormSchema,
  createAccountFormSchema,
} from "@/utils/schemas/create-account-form-schema";
import { zodResolver } from "@hookform/resolvers/zod";

import { useZStore } from "@/utils/z-store";
import cn from "@/utils/cn";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { useState } from "react";
import { signIn, signOut } from "next-auth/react";
import SignupInputField from "../SignupInputField";

interface CreateAccountFormModalProps {
  inviteToken?: string;
  className?: string;
}

const CreateAccountFormModal: React.FC<CreateAccountFormModalProps> = ({
  inviteToken,
  className,
}) => {
  // const pathname = usePathname();
  const { actions } = useZStore();
  const [loading, setLoading] = useState(false);
  const methods = useForm<CreateAccountFormSchema>({
    resolver: zodResolver(createAccountFormSchema),
  });
  // const [, setFormProcessing] = useState(false);
  const isPasswordInvalid =
    methods.formState.errors.password?.message ||
    methods.formState.errors.confirmPassword?.message;

  const acceptInvitation = async (email: string, token: string) => {
    try {
      const response = await axios.post("/api/invitations/accept", {
        token,
        email,
      });

      if (response.status === 200) {
        toast.success("Invitation accepted successfully.", {
          position: "bottom-left",
        });
      } else {
        toast.error(response.data.message || "Failed to accept invitation.", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.message || "Failed to accept invitation.";
        toast.error(errorMessage, {
          position: "bottom-left",
        });
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          position: "bottom-left",
        });
      }
    }
  };

  const registerUser = async (formData: any) => {
    setLoading(true);
    if (formData.password !== formData.confirmPassword) {
      // console.error("Passwords do not match");
      return;
    }

    try {
      // Sign out any existing session first
      await signOut({ redirect: false });
      actions.closeModal();
      actions.resetStore();
      // console.log(formData);
      const response = await axios.post("/api/auth/register", formData);
      // console.log(response.data);
      toast.success("Successfully signed up!", {
        position: "bottom-left",
      });
      if (response.status === 200) {
        // actions.openModal({ modalName: "sign-in" });
        if (inviteToken) {
          await acceptInvitation(formData.email, inviteToken);
        }
        await signIn("credentials", {
          email: formData.email,
          password: formData.password,
          callbackUrl: "/dashboard/home",
          redirect: true,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle axios-specific errors
        const errorMessage =
          error.response?.data?.message || "Failed to sign up.";
        toast.error(errorMessage, {
          position: "bottom-left",
        });
      } else {
        // Handle other errors
        // console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred. Please try again.", {
          position: "bottom-left",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      // Sign out any existing session first
      await signOut({ redirect: false });
      actions.closeModal();
      actions.resetStore();

      if (inviteToken) {
        localStorage.setItem("inviteToken", inviteToken);
      }
      await signIn("google", {
        callbackUrl: "/dashboard/home",
      });
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <FormProvider {...methods}>
      <div
        className={cn(
          "relative flex w-[262px] h-[693px] items-center justify-center overflow-hidden rounded-[20px]  bg-white md:h-[418px] md:w-[550px] xl:h-[596px] xl:w-[1067px]",
          isPasswordInvalid ? "xl:h-[680px] md:h-[510px] " : "",
        )}
      >
        {/* side image */}
        <div className="relative hidden h-full w-[35%] overflow-hidden bg-kd-lte-blue text-white md:block">
          <div className="absolute -right-36 -top-36 -z-0 h-[227.25px] w-[229.5px] rotate-90 rounded-full bg-gradient-to-tl  from-white/40 via-transparent to-white/0 md:-right-28 md:-top-40 xl:-right-36 xl:-top-52 xl:h-[303px] xl:w-[306px]"></div>

          <div
            className={cn(
              "absolute -bottom-52 -left-44 -z-0 rounded-full bg-gradient-to-bl from-white/30 via-transparent to-white/0 md:-bottom-[120px] md:-left-[70px] md:size-[181.5px] xl:-bottom-[168px] xl:-left-20 xl:size-[242px]",
            )}
          ></div>
          <div className=" flex h-full justify-center">
            <div className="md:pt-[135px] xl:pt-[228px]">
              <div className=" flex px-3 text-center text-xs font-semibold md:text-[23px] md:leading-[30px] xl:text-[35px] xl:leading-[40px]">
                Welcome Back
              </div>
              <div className=" flex  justify-center md:pb-[21px] md:pt-[12px] xl:pb-[26px] xl:pt-[13px]">
                <div className=" text-center text-[#D1D1D6] md:h-[48px] md:w-[158px] md:text-[10.5px] xl:h-[50px] xl:w-[257px] xl:text-sm">
                  Do you already have an account? Please login with your
                  personal info
                </div>
              </div>
              <div className=" flex justify-center">
                <Button
                  variant="inverse"
                  onClick={() => actions.openModal({ modalName: "sign-in" })}
                  className={cn(
                    "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 md: text-[12px] font-semibold xl:h-10 xl:w-[107px] xl:text-base ",
                  )}
                  disabled={loading}
                >
                  Sign in
                </Button>
              </div>
              <div className="flex justify-center md:pt-[67.5px] xl:pt-[133px]">
                <div className="text-center text-[#D1D1D6] md:w-[140px] md:text-[6px] xl:w-[206px] xl:text-[8px]">
                  By signing up, you agree to Email Audit Engine`&lsquo;s Terms
                  of Services and Privacy Policy.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cn(
            "h-full w-full bg-white md:pb-[28px] pb-[10px] text-[#444A6D] md:w-[65%] pt-[42px] md:pt-[42px] xl:pb-[56px] xl:pt-[69px] ",
            {
              "h-fit": isPasswordInvalid,
            },
          )}
        >
          <div className=" flex justify-center px-[29px] text-center text-[23px] font-semibold text-[#05004E] md:px-0 xl:text-[35px]">
            Create A Free Account
          </div>

          {/* signup with google and apple */}
          <div className="flex justify-center pb-3 pt-[10px] md:pb-[19.25px] md:pt-[16px] xl:pb-[26px] xl:pt-[36px] ">
            {/* <Button
              onClick={() => {
                alert(1);
              }}
              className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text xl:h-[60px] xl:rounded-[16px]"
            >
              <AiFillApple className="size-5" />
              <span>Sign up with Apple</span>
            </Button> */}

            <Button
              id="google-sign-up"
              onClick={handleGoogleSignIn}
              className="flex h-[44.77px] w-[47.75px] items-center justify-center rounded-xl border border-[#EDF2F6] bg-[#FAFBFC]  text-kd-gray-text xl:h-[60px] xl:w-[64px] xl:rounded-[16px]"
            >
              <FcGoogle className="size-[18px] xl:size-6" />
            </Button>
          </div>
          <div className=" flex items-center justify-center space-x-2 xl:space-x-5  ">
            <div className="h-px w-1/5 bg-[#EDF2F6]"></div>
            <div className=" text-[10.5px] text-[#96A5B8] xl:text-sm">
              or sign up with email
            </div>
            <div className="h-px w-1/5 bg-[#EDF2F6]"></div>
          </div>
          <form onSubmit={methods.handleSubmit(registerUser)}>
            <div className=" flex justify-center pt-2 md:pt-[18px] xl:pt-[25px]">
              <div>
                <SignupInputField
                  id="email-sign-up"
                  name="email"
                  className="mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal md:mb-[8px] md:h-[40px] md:w-[280px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[592px] xl:rounded-2xl xl:px-[24px] "
                  placeholder="Email address"
                  isRequired={true}
                  errorTextPosition="right"
                />

                <SignupInputField
                  name="username"
                  className="mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal md:mb-[8px] md:h-[40px] md:w-[280px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[592px] xl:rounded-2xl"
                  placeholder="Username"
                  isRequired={true}
                  errorTextPosition="right"
                />

                <div className="flex flex-col md:flex-row md:space-x-2 xl:space-x-5">
                  <SignupInputField
                    name="password"
                    type="password"
                    className="mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal tracking-[4px] placeholder:tracking-normal md:mb-[8px] md:h-[40px] md:w-[136px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[286px] xl:rounded-2xl"
                    placeholder="Password"
                    isRequired={true}
                    errorTextPosition="right"
                  />

                  <SignupInputField
                    name="confirmPassword"
                    type="password"
                    className=" mb-2 flex h-[40px] w-[204px] rounded-[12px] px-[15px] font-normal tracking-[4px] placeholder:tracking-normal md:mb-[8px] md:h-[40px] md:w-[136px] md:rounded-xl md:px-[18px] xl:mb-[16px] xl:h-[60px] xl:w-[286px] xl:rounded-2xl"
                    placeholder="Confirm Password"
                    isRequired={true}
                    errorTextPosition="right"
                  />
                </div>
              </div>
            </div>
            {methods.formState.errors.password && (
              <div
                className={cn(
                  "mb-2 pl-[29px] md:pl-[41px] xl:-mb-[39px] xl:pl-[55px]",
                )}
              >
                <ul className="w-[155px] rounded-lg bg-[#FFF1F2] p-2 text-[8px]  text-[#FA5A7D] md:-mt-1 xl:-mt-2">
                  <li>• Be at least 6 characters</li>
                  <li>
                    • Must have at least one uppercase letter
                    (&apos;A&apos;-&apos;Z&apos;)
                  </li>

                  <li>
                    • Must have at least one number
                    (&apos;0&apos;-&apos;9&apos;)
                  </li>
                </ul>
              </div>
            )}

            <div className="flex items-center justify-center pt-1 md:pt-5 xl:pt-1">
              <Button
                id="sign-up"
                type="submit"
                variant="primary"
                className={cn(
                  "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 text-[12px] font-semibold leading-5 xl:h-10 xl:w-[107px] xl:text-base ",
                )}
                disabled={loading}
              >
                Sign up
              </Button>
            </div>
          </form>
          <div
            className={cn(
              "relative mt-3 size-full overflow-hidden bg-kd-lte-blue text-white md:hidden",
              {
                hidden: isPasswordInvalid,
              },
            )}
          >
            <div className="absolute -right-36 -top-36 -z-0 h-[227.25px] w-[229.5px]  rotate-90 rounded-full bg-gradient-to-tl  from-white/40 via-transparent to-white/0 xl:-right-36 xl:-top-52"></div>

            <div className=" flex h-full justify-center">
              <div className=" px-[29px] pt-[42px]">
                <div className=" flex justify-center px-3 text-center text-lg font-semibold ">
                  Welcome Back
                </div>
                <div className=" flex  justify-center py-2">
                  <div className=" w-[170px] text-center text-xs text-[#D1D1D6]">
                    Do you already have an account? Please login with your
                    personal info
                  </div>
                </div>
                <div className=" flex justify-center">
                  <Button
                    variant="inverse"
                    onClick={() => actions.openModal({ modalName: "sign-in" })}
                    className={cn(
                      "md:h-[30px] md:w-[80.25px] whitespace-nowrap rounded-lg px-4 md: text-[12px] font-semibold xl:h-10 xl:w-[107px] xl:text-base ",
                    )}
                    disabled={loading}
                  >
                    Sign in
                  </Button>
                </div>
                <div className="flex justify-center pt-4">
                  <div className="text-center text-[8px] text-[#D1D1D6]">
                    By signing up, you agree to Email Audit Engine`&lsquo;s
                    Terms of Services and Privacy Policy.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* close icon */}
        <div className="" onClick={() => actions.closeModal()}>
          <Image
            src={CloseIcon}
            alt="close icon"
            className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateAccountFormModal;
