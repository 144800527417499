"use client";
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";
import Button from "@/components/buttons";
import TextComponent from "../TextComponent";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";
import toast from "react-hot-toast";

interface Invitation {
  email: string;
  role: string;
}

export default function InvitationModal() {
  const { data: session, status } = useSession();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { actions } = useZStore();

  const [loading, setLoading] = useState(true);
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [error, setError] = useState<string | null>(null);

  const token = searchParams.get("inviteToken");
  const modalWrapperClasses = cn(
    "relative rounded-lg xl:rounded-[20px] bg-white p-6 shadow-md xl:h-[278px] xl:w-[628px]",
    "flex flex-col items-center justify-center",
  );

  useEffect(() => {
    if (!token) {
      setLoading(false);
      setError("No invitation token provided.");
      return;
    }

    const verifyToken = async () => {
      try {
        const response = await fetch(`/api/invitations/verify?token=${token}`);
        const data = await response.json();

        if (response.ok) {
          setInvitation(data.invitation);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError("Error verifying invitation.");
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  const handleAccept = async () => {
    if (
      status === "authenticated" &&
      session?.user?.email === invitation?.email
    ) {
      try {
        const response = await fetch(`/api/invitations/accept`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            email: session?.user?.email,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success("Invitation accepted successfully.");
          actions.closeModal();
          router.push("/dashboard/home");
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError("Error accepting invitation.");
      }
    } else {
      // Redirect to sign-up with inviteToken
      router.push(`/?modal=sign-up&inviteToken=${token}`);
    }
  };

  if (loading) {
    return (
      <div className={modalWrapperClasses}>
        <div className="text-center">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={modalWrapperClasses}>
        {/* Close Button */}
        <button
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          aria-label="Close"
          onClick={() => actions.closeModal()}
        >
          &times;
        </button>

        <div className="text-center text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className={modalWrapperClasses}>
      {/* Close Button */}
      <button
        className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        aria-label="Close"
        onClick={() => actions.closeModal()}
      >
        &times;
      </button>

      {/* Heading */}
      <TextComponent type="h2" className="mb-2 text-center text-[#05004E]">
        You Have Been Invited!
      </TextComponent>

      {/* Subtext */}
      <TextComponent
        type="body"
        className="mb-6 text-center text-[#96A5B8] xl:mb-8"
      >
        {/* {status === "authenticated"
          ? "Continue to accept and access shared data."
          : "Create an account to accept invitation and access shared data."} */}
        Continue to get started and access shared data.
      </TextComponent>

      {/* CTA Button */}
      <div className="text-center">
        <Button variant="primary" onClick={handleAccept}>
          {/* {status === "authenticated"
            ? "Continue to Dashboard"
            : "Create a Free Account"} */}
          Activate Shared Access
        </Button>
      </div>
    </div>
  );
}
